/**
 * @generated SignedSource<<9aa4f0ca8a6641eadbd945985f591785>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurriculumConnectSlackOnboardingDrawerQuery$variables = {
  id: string;
};
export type CurriculumConnectSlackOnboardingDrawerQuery$data = {
  readonly usage: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingSlackContentFragment">;
  } | null;
};
export type CurriculumConnectSlackOnboardingDrawerQuery = {
  response: CurriculumConnectSlackOnboardingDrawerQuery$data;
  variables: CurriculumConnectSlackOnboardingDrawerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurriculumConnectSlackOnboardingDrawerQuery",
    "selections": [
      {
        "alias": "usage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MemberOnboardingSlackContentFragment"
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurriculumConnectSlackOnboardingDrawerQuery",
    "selections": [
      {
        "alias": "usage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerHasCompleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "systemTaskKind",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SlackConnection",
                        "kind": "LinkedField",
                        "name": "slack",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SlackWorkspace",
                            "kind": "LinkedField",
                            "name": "workspace",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity",
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57ec94c04124fa13f7408c5e604a2089",
    "id": null,
    "metadata": {},
    "name": "CurriculumConnectSlackOnboardingDrawerQuery",
    "operationKind": "query",
    "text": "query CurriculumConnectSlackOnboardingDrawerQuery(\n  $id: ID!\n) {\n  usage: node(id: $id) {\n    __typename\n    ... on ContentUsage {\n      id\n      ...MemberOnboardingSlackContentFragment\n    }\n    id\n  }\n}\n\nfragment MemberOnboardingContentHeaderFragment on ContentUsage {\n  content {\n    name\n    description\n    systemTaskKind\n    id\n  }\n}\n\nfragment MemberOnboardingSlackAutoConnectSuccessFragment on ContentUsage {\n  content {\n    organization {\n      slack {\n        workspace {\n          name\n          url\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment MemberOnboardingSlackContentFragment on ContentUsage {\n  viewerHasCompleted\n  content {\n    systemTaskKind\n    id\n  }\n  ...useMemberOnboardingFormStoreFragment\n  ...MemberOnboardingContentHeaderFragment\n  ...MemberOnboardingSlackManualConnectionStepsFragment\n  ...MemberOnboardingSlackAutoConnectSuccessFragment\n  ...useTrackContentCompletionFragment\n}\n\nfragment MemberOnboardingSlackManualConnectionStepsFragment on ContentUsage {\n  content {\n    organization {\n      slack {\n        workspace {\n          name\n          url\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n  ...MemberOnboardingSlackValidateConnectionFragment\n  ...MemberOnboardingSlackRequestInviteFragment\n}\n\nfragment MemberOnboardingSlackRequestInviteFragment on ContentUsage {\n  ...useMemberOnboardingFormStoreFragment\n}\n\nfragment MemberOnboardingSlackValidateConnectionFragment on ContentUsage {\n  content {\n    organization {\n      slack {\n        workspace {\n          name\n          url\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment useMemberOnboardingFormStoreFragment on ContentUsage {\n  id\n  viewerHasCompleted\n}\n\nfragment useTrackContentCompletionFragment on ContentUsage {\n  id\n  entity\n  content {\n    type\n    label\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1095d80b130d1e6654b46fc6f7047a9d";

export default node;
