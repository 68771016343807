import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentUsagePrerequisiteList from "@/content-usage/ContentUsagePrerequisiteList"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { ContentModuleCardFragment$key } from "@/content-usage/modules/components/__generated__/ContentModuleCardFragment.graphql"
import ContentUsageLockIcon from "@/content-usage/modules/list/ContentUsageLockIcon"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import useTruncateStyles from "@assets/style/util/useTruncateStyles"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions, getTimeDifferenceAsText } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import ContentModuleProgressIcon from "./ContentModuleProgressIcon"

interface Props extends TestIDProps {
  contentUsageKey: ContentModuleCardFragment$key
  className?: string
}

function ContentModuleCard(props: Props) {
  const { contentUsageKey, className, testid = "ContentModuleCard" } = props

  const activeProduct = useActiveProduct()!
  const drawer = useContentUsageDrawer()
  const history = useHistory()
  const showOnHoverClasses = useShowOnHoverStyles()
  const truncateClasses = useTruncateStyles({
    numberOfLines: 2,
    overflowWrap: "anywhere",
  })
  const timeZone = useUserTimezone()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const contentUsage = useFragment<ContentModuleCardFragment$key>(
    graphql`
      fragment ContentModuleCardFragment on ContentUsage {
        id
        releasedAt
        isLocked
        content {
          name
        }
        curriculum {
          product {
            slug
          }
        }
        content {
          children {
            edges {
              node {
                id
                viewerHasCompleted
                content {
                  type
                }
              }
            }
          }
        }
        ...ContentUsageLockIconFragment
        ...ContentModuleAdminDropdownFragment
        ...usePermissionsFragment
      }
    `,
    contentUsageKey
  )

  const moduleItems = Relay.connectionToArray(contentUsage.content.children)
  const isReleased = ContentModuleUtils.isReleased(contentUsage)
  const completedItems = moduleItems.filter((mi) => mi.viewerHasCompleted)
  const permissions = usePermissions(contentUsage)
  const canManage = permissions.has("curriculum.manage")
  const classes = useStyles({
    isReleased,
    isLocked: contentUsage.isLocked,
    canManage,
  })

  return (
    <>
      <DiscoContainerButton
        className={classNames(showOnHoverClasses.hoverable, classes.root)}
        testid={testid}
        onClick={handleClick}
      >
        <div className={classNames(classes.container, className)}>
          <div className={classes.header}>
            <div className={classes.contentName}>
              {contentUsage.isLocked && (
                <ContentUsageLockIcon
                  usageKey={contentUsage}
                  disabled={contentUsage.isLocked && !canManage}
                />
              )}
              <DiscoText
                variant={"body-md-600"}
                className={truncateClasses.truncateMultipleLines}
                testid={`${testid}.title`}
              >
                {contentUsage.content.name}
              </DiscoText>
            </div>

            {renderRightHeaderContent()}
          </div>

          {contentUsage.releasedAt && (
            <DiscoText variant={"body-xs"} color={"text.secondary"}>
              {releaseDateText()}
            </DiscoText>
          )}
        </div>
      </DiscoContainerButton>

      <ContentUsagePrerequisiteList
        contentUsageId={contentUsage.id}
        isOpen={isOpen}
        openPrerequisites={onOpen}
        closePrerequisitesModal={onClose}
        testid={`ContentUsageLockIcon.prerequisitesList.${contentUsage.content?.name}`}
      />
    </>
  )

  function releaseDateText() {
    if (!contentUsage.releasedAt) return null

    const formatDate = formatDateWithOptions({
      timeZone,
      format: DATE_FORMAT.DEFAULT_FULL_MONTH,
      shouldShiftDateToCompensateForTimezone: false,
    })

    const releasedAt = formatDate(new Date(contentUsage.releasedAt))

    return isReleased
      ? `Released on ${releasedAt}`
      : `Available ${getTimeDifferenceAsText(new Date(contentUsage.releasedAt))}`
  }

  function handleClick() {
    // If not released, only admins can open
    if (!canManage && !isReleased) return

    // If locked and released, anyone can open
    if (!canManage && contentUsage.isLocked) {
      if (isOpen) onClose()
      else onOpen()
      return
    }

    const linkableItems = moduleItems // FUTURE: filter out slack invite item

    // Link to the first item, except for learners link to their next incomplete item.
    let itemToLink = linkableItems[0]
    if (!canManage) {
      const firstIncomplete = linkableItems.find((mi) => !mi.viewerHasCompleted)
      if (firstIncomplete) {
        itemToLink = firstIncomplete
      }
    }

    // Empty module, link to curriculum page
    if (!itemToLink) {
      history.push({
        pathname: generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
          productSlug: activeProduct.slug,
        }),
        hash: contentUsage.id,
      })
      return
    }

    drawer.open({
      drawerContentUsageId: itemToLink.id,
    })
  }

  function renderRightHeaderContent() {
    if (contentUsage.isLocked || !isReleased || canManage) return null

    return (
      <ContentModuleProgressIcon
        testid={`${testid}.progress-icon`}
        totalItems={moduleItems.length}
        totalCompletedItems={completedItems.length}
      />
    )
  }
}

type StyleProps = {
  isReleased?: boolean
  isLocked?: boolean
  canManage?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ isReleased, isLocked, canManage }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.big,
    background: theme.palette.background.paper,
    transition: "box-shadow 0.2s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    boxShadow: theme.palette.groovyDepths.xs,
    border: theme.palette.constants.borderDashboardCard,

    ...styleIf(!isReleased && !canManage, {
      pointerEvents: "none",
      cursor: "default !important",
    }),
    ...styleIf(!canManage && (!isReleased || isLocked), {
      opacity: `0.65 !important`,
    }),

    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  }),
  container: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2.5),
    width: "100%",
    flex: "1 1 auto",
  },
  contentName: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(0.5),
  },
}))

export function ContentModuleCardSkeleton() {
  const classes = useStyles({})
  const theme = useTheme()

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.root} style={{ padding: theme.spacing(2) }}>
        <div className={classes.header}>
          <div style={{ width: "90%" }}>
            <DiscoTextSkeleton width={"80%"} />
            <DiscoTextSkeleton width={"50%"} marginTop={0.5} />
          </div>
          <Skeleton variant={"circle"} width={"40px"} height={"40px"} />
        </div>
        <div style={{ width: "100%", display: "flex", gap: theme.spacing(1) }}>
          <DiscoTextSkeleton width={"33%"} />
          <DiscoTextSkeleton width={"33%"} />
        </div>
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentModuleCard,
  skeleton: ContentModuleCardSkeleton,
})
