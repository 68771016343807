import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentModuleAdminDropdown from "@/content-usage/modules/actions/ContentModuleAdminDropdown"
import CreateContentModuleItemButton from "@/content-usage/modules/actions/CreateContentModuleItemButton"
import ContentModuleCompletedUsersAvatarStack from "@/content-usage/modules/components/ContentModuleCompletedUsersAvatarStack"
import ContentModuleProgressIcon from "@/content-usage/modules/components/ContentModuleProgressIcon"
import ContentModuleReleaseCountdown from "@/content-usage/modules/components/ContentModuleReleaseCountdown"
import ContentModuleReleaseDate from "@/content-usage/modules/components/ContentModuleReleaseDate"
import ContentModuleEvents from "@/content-usage/modules/list/ContentModuleEvents"
import ContentUsageList from "@/content-usage/modules/list/ContentUsageList"
import ContentUsageLockIcon from "@/content-usage/modules/list/ContentUsageLockIcon"
import { ContentModuleListItemFragment$key } from "@/content-usage/modules/list/__generated__/ContentModuleListItemFragment.graphql"
import { formatTimeMinutes } from "@/content-usage/utils/timeUtils"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useNotificationsContext } from "@/core/context/NotificationsContext"
import CaretIcon from "@/core/ui/iconsax/bold/arrow-down.svg"
import ReorderIcon from "@/core/ui/iconsax/linear/custom-dots-tune.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useLocalStorageState from "@/onboarding/utils/hooks/useLocalStorageState"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CountBadge from "@components/square-count-badge/CountBadge"
import { DiscoIcon, DiscoIconButton, DiscoText, DiscoTooltip } from "@disco-ui"
import { Collapse, Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import usePermissions from "@utils/hook/usePermissions"
import useScrollToHash from "@utils/hook/useScrollToHash"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { subMinutes } from "date-fns"
import { useCallback, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"

interface ContentModuleListItemProps extends TestIDProps {
  contentUsageKey: ContentModuleListItemFragment$key
  moduleIndex: number
  canReorder?: boolean
  showProgressIcon?: boolean
  hideIfEmpty?: boolean
  isMostRecentlyReleased?: boolean
  filterContentLabelIds?: GlobalID[]
  alwaysStartCollapsed?: boolean
}

function ContentModuleListItem({
  testid,
  contentUsageKey,
  moduleIndex,
  canReorder = true,
  showProgressIcon = true,
  hideIfEmpty,
  isMostRecentlyReleased = false,
  filterContentLabelIds,
  alwaysStartCollapsed = false,
}: ContentModuleListItemProps) {
  const theme = useTheme()
  const activeProduct = useActiveProduct()!
  const { contentTimeToComplete } = useFeatureFlags()
  const { getUnreadNotifications } = useNotificationsContext()

  const contentUsage = useFragment<ContentModuleListItemFragment$key>(
    graphql`
      fragment ContentModuleListItemFragment on ContentUsage
      @argumentDefinitions(contentLabelIds: { type: "[ID!]" }) {
        id
        releasedAt
        viewerHasCompleted
        isLocked
        hasPrerequisites
        content {
          id
          type
          name
          description
          children(contentLabelIds: $contentLabelIds) {
            totalCount
            edges {
              node {
                id
                isLocked
                hasPrerequisites
                content {
                  timeEstimateMinutes
                }
              }
            }
          }
          viewerChildContentCompletions {
            totalCount
          }
        }
        occurrences {
          totalCount
        }
        ...CreateContentModuleItemButtonFragment
        ...ContentModuleReleaseCountdownFragment
        ...ContentModuleReleaseDateFragment
        ...ContentModuleAdminDropdownFragment
        ...usePermissionsFragment
        ...ContentModuleEvents_ContentUsageFragment
        ...ContentUsageLockIconFragment
      }
    `,
    contentUsageKey
  )

  // get total notifications for all children
  const children = Relay.connectionToArray(contentUsage.content.children)
  const unreadSubmissionNotifications = children.flatMap((cu) =>
    getUnreadNotifications({
      contentUsageId: cu.id,
      kinds: ["assignment-submitted", "quiz-submitted"],
    })
  )
  const totalTimeEstimateMinutes = children.some(
    (cu) => cu.content?.timeEstimateMinutes == null
  )
    ? null
    : children.reduce((acc, cu) => acc + (cu.content?.timeEstimateMinutes || 0), 0)

  const permissions = usePermissions(contentUsage)
  const showManageActions = permissions.has("content.manage")

  const totalCompletedItems =
    contentUsage.content.viewerChildContentCompletions?.totalCount ?? 0
  const isReleased = ContentModuleUtils.isReleased(contentUsage)
  const totalModuleItems = contentUsage.content.children?.totalCount ?? 0
  const hasItems = Boolean(totalModuleItems)
  const hasEvents = Boolean(contentUsage?.occurrences?.totalCount)

  const progressStatus = contentUsage.viewerHasCompleted
    ? "complete"
    : totalCompletedItems && hasItems
    ? "incomplete"
    : "not-started"

  const [storageIsCollapsed, setStorageIsCollapsed] = useLocalStorageState({
    key: "disco-modules-collapsed",
    propertyName: contentUsage.id,
    defaultValue: isCollapsedByDefault(),
  })

  // Allow bypassing localStorage state and be collapsed on first load
  const [isCollapsed, setStateIsCollapsed] = useState(initialStateIsCollapsed())
  const setIsCollapsed = useCallback(
    (val: boolean) => {
      setStateIsCollapsed(val)
      if (alwaysStartCollapsed) return
      setStorageIsCollapsed(val)
    },
    [alwaysStartCollapsed, setStorageIsCollapsed]
  )

  const classes = useStyles({
    isReleased,
    isLocked: contentUsage.isLocked,
    canManage: showManageActions,
  })
  const showOnHoverClasses = useShowOnHoverStyles({ hideOnTouchscreen: true })

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  // Close the module when completed
  ContentModuleUtils.useOnCurriculumModuleCompleted(
    useCallback(
      (moduleId) => {
        if (moduleId === contentUsage.id) {
          setStateIsCollapsed(true)
          // Ensure it also gets set to collapsed in localStorage when completed
          setStorageIsCollapsed(true)
        }
      },
      [contentUsage.id, setStorageIsCollapsed]
    )
  )

  const showReorderSectionIcon = !isSmDown && showManageActions && canReorder

  const moduleRef = useRef<HTMLDivElement | null>(null)
  useScrollToHash(moduleRef, contentUsage.id)

  if (hideIfEmpty && !hasItems) return null

  return (
    <Grid item xs={12}>
      <div
        ref={moduleRef}
        id={contentUsage.id}
        data-testid={testid}
        data-test-iscollapsed={isCollapsed}
        className={classNames(classes.sectionContainer, showOnHoverClasses.hoverable)}
      >
        <div className={classes.sectionHeader}>
          <div className={classes.headerContainer}>
            <div
              className={classes.lhsContainer}
              role={"button"}
              tabIndex={-1}
              onClick={() => setIsCollapsed(!isCollapsed)}
              onKeyPress={() => setIsCollapsed(!isCollapsed)}
            >
              <div>
                <div className={classes.titleHeader}>
                  {/* Drag/drop icon */}
                  {showReorderSectionIcon && (
                    <div
                      className={classNames(
                        classes.dragButtonContainer,
                        showOnHoverClasses.showable
                      )}
                    >
                      <ReorderIcon width={16} height={16} />
                    </div>
                  )}

                  {/* Toggle collapse button */}
                  <DiscoIconButton
                    size={"small"}
                    className={classes.expandButton}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    testid={`${testid}.collapse-button`}
                  >
                    <CaretIcon
                      className={classNames(classes.collapsibleCaret, {
                        isOpen: !isCollapsed,
                      })}
                    />
                  </DiscoIconButton>

                  <div className={classes.titleWithTooltip}>
                    {/* Section Title */}
                    {((showManageActions && contentUsage.hasPrerequisites) ||
                      contentUsage.isLocked) && (
                      <ContentUsageLockIcon usageKey={contentUsage} />
                    )}
                    <DiscoText
                      variant={"heading-md"}
                      testid={"ContentModuleListItem.title"}
                      className={classes.sectionTitle}
                      truncateText={2}
                    >
                      {contentUsage.content.name}
                      {contentUsage.content.description && (
                        <DiscoTooltip
                          className={classes.tooltip}
                          content={contentUsage.content.description}
                          data-testid={"ContentModuleListItem.description-tooltip"}
                        />
                      )}

                      {totalTimeEstimateMinutes &&
                      totalTimeEstimateMinutes > 0 &&
                      contentTimeToComplete ? (
                        <DiscoText
                          marginLeft={1}
                          variant={"body-xs-500"}
                          color={"groovy.neutral.600"}
                          testid={"ContentModuleListItem.timeEstimate"}
                        >
                          {formatTimeMinutes(totalTimeEstimateMinutes)}
                        </DiscoText>
                      ) : null}
                    </DiscoText>
                  </div>
                </div>

                <div className={classes.releaseDate}>
                  <ContentModuleReleaseDate
                    contentUsageKey={contentUsage}
                    copyVariant={"on"}
                    dateFormat={DATE_FORMAT.DEFAULT_FULL_MONTH}
                  />
                </div>
              </div>
            </div>

            {/* RHS container */}
            <div className={classes.rhsContainer}>
              <div className={classes.dateContainer}>
                <div className={classes.progressContainer}>
                  {/* Notification Badge */}
                  {isCollapsed && unreadSubmissionNotifications.length > 0 && (
                    <CountBadge count={unreadSubmissionNotifications.length} />
                  )}
                  {/* Module Completions */}
                  {isReleased && activeProduct.curriculum?.showCompletedUsers && (
                    <ContentModuleCompletedUsersAvatarStack
                      contentUsageId={contentUsage.id}
                    />
                  )}
                  {/* Add item button */}
                  {showManageActions ? (
                    <>
                      <CreateContentModuleItemButton
                        testid={`${testid}.create-item-button`}
                        contentUsageKey={contentUsage}
                      >
                        {(btnProps) => (
                          <DiscoIconButton
                            {...btnProps}
                            width={40}
                            height={40}
                            svgStyles={{ height: 20, width: 20 }}
                            className={classes.addButton}
                          >
                            <DiscoIcon icon={"add-circle"} />
                          </DiscoIconButton>
                        )}
                      </CreateContentModuleItemButton>

                      {/* Admin dropdown */}
                      <ContentModuleAdminDropdown
                        testid={`${testid}.admin-dropdown`}
                        contentUsageKey={contentUsage}
                        className={classes.adminActions}
                      />
                    </>
                  ) : (
                    <div className={classes.progress}>
                      {showProgress() && (
                        <ContentModuleProgressIcon
                          totalCompletedItems={totalCompletedItems}
                          totalItems={totalModuleItems || 0}
                          testid={`${testid}.${progressStatus}`}
                          className={classes.progressIcon}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Items or unreleased message */}
        <Collapse in={!isCollapsed} mountOnEnter>
          {showManageActions || isReleased ? (
            <>
              <ContentModuleEvents
                contentUsageKey={contentUsage}
                testid={`ContentUsageListItem.events`}
                variant={isMobile ? "compact" : "default"}
              />
              {hasEvents && hasItems && (
                <DiscoText
                  variant={"body-xs-500-uppercase"}
                  color={"text.secondary"}
                  marginTop={1.75}
                  marginBottom={1.25}
                  marginLeft={0.5}
                  component={"div"}
                >
                  {"Content"}
                </DiscoText>
              )}
              <ContentUsageList
                contentId={contentUsage.content.id}
                filterContentLabelIds={filterContentLabelIds}
                showManageActions={showManageActions}
                isCollapsed={isCollapsed}
                canReorder={canReorder}
              />
            </>
          ) : (
            <ContentModuleReleaseCountdown contentUsageKey={contentUsage} />
          )}
        </Collapse>
      </div>
    </Grid>
  )

  /** The default isCollapsed state of the module */
  function isCollapsedByDefault(): boolean {
    // Unreleased modules are expanded by default for admins
    if (showManageActions && !isReleased) return false
    // The most recently released module is expanded by default for everyone
    if (isMostRecentlyReleased) return false
    return true
  }

  function initialStateIsCollapsed() {
    // Initial collapse state when not in the dashboard block
    if (!alwaysStartCollapsed) return storageIsCollapsed
    // If this is the first module in a newly created product, temporarily expand it
    if (
      moduleIndex === 0 &&
      new Date(activeProduct.creationDatetime) > subMinutes(new Date(), 5)
    ) {
      return false
    }
    return true
  }

  function showProgress() {
    if (!showProgressIcon) return false
    // Admins don't see completion progress
    if (showManageActions) return false
    // Learners don't see completion progress if the module is locked
    if (contentUsage.isLocked) return false
    // Learners don't see completion progress if the module is unreleased
    if (!isReleased) return false
    return true
  }
}

type StyleProps = {
  isReleased?: boolean
  isLocked?: boolean
  canManage?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  sectionContainer: ({ isReleased, isLocked, canManage }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.background.paper,
    scrollMarginTop: "70px",
    padding: theme.spacing(1, 2.5),
    marginBottom: theme.spacing(2),
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
    ...styleIf(!canManage && (!isReleased || isLocked), {
      opacity: 0.65,
    }),
  }),
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0),

    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  titleWithTooltip: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: "20px",
    maxWidth: "700px",
    margin: theme.spacing(-0.5, 3, -0.5, 0),
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  titleHeader: {
    display: "flex",
    alignItems: "flex-start",
  },
  releaseDate: {
    marginLeft: theme.spacing(4),
  },
  lhsContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  rhsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1),

    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      maxWidth: "100%",
    },
  },
  addButton: {
    padding: 0,
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "flex-end",
    gap: theme.spacing(1),

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "flex-end",
    },
  },
  dateContainer: {
    position: "relative",
    alignItems: "center",
    textAlign: "right",
    display: "flex",
    flexGrow: 1,
    gap: theme.spacing(1),

    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  expandButton: {
    marginTop: theme.spacing(-0.25),
  },
  collapsibleCaret: {
    transform: "rotate(-90deg)",
    transition: "transform 0.2s ease-in-out",
    padding: theme.spacing(0.5),
    "& path": {
      color: theme.palette.text.primary,
    },
    "&.isOpen": {
      transform: "rotate(0deg)",
    },
  },
  tooltip: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    display: "inline",
    margin: theme.spacing(-0.5, 0, 0, 1),
  },
  dragButtonContainer: {
    position: "absolute",
    left: theme.spacing(-1.5),
    top: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
  },
  progress: {
    width: "48px",
  },
  progressIcon: {
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 1, 0, 1.5),
    },
  },
  adminActions: {
    width: 40,
    height: 40,
    marginLeft: theme.spacing(-1),
  },
}))

export const ContentModuleListItemSkeleton: React.FC = () => {
  const classes = useStyles({})

  return (
    <Grid item xs={12}>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>
          <div className={classes.headerContainer}>
            <div className={classes.lhsContainer} tabIndex={-1}>
              <div>
                <div className={classes.titleWithTooltip}>
                  <DiscoText variant={"heading-md"} className={classes.sectionTitle}>
                    <Skeleton height={32} width={150} />
                  </DiscoText>
                </div>
              </div>
            </div>

            <div className={classes.rhsContainer}>
              <div className={classes.dateContainer}>
                <div className={classes.progressContainer}>
                  <Skeleton height={24} width={24} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default ContentModuleListItem
