/**
 * @generated SignedSource<<eeade84e28dfef2d5c4c20b07503b01a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageGridItemFragment$data = {
  readonly content: {
    readonly coverPhoto: string | null;
    readonly coverVideo: string | null;
    readonly coverVideoAssetUsage: {
      readonly asset: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"DiscoVideoFragment">;
      };
      readonly id: string;
    } | null;
    readonly description: string | null;
    readonly id: string;
    readonly label: string;
    readonly name: string | null;
    readonly productId: string | null;
    readonly showCoverPlayIcon: boolean;
    readonly thumbnailUrl: string | null;
    readonly type: ContentType;
    readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentFragment" | "ContentFooterSection_ContentFragment">;
  };
  readonly hasViewerBookmarked: boolean;
  readonly id: string;
  readonly module: {
    readonly usages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly collection: {
            readonly app: {
              readonly customAppTitle: string | null;
            };
            readonly id: string;
          } | null;
          readonly curriculum: {
            readonly id: string;
            readonly product: {
              readonly name: string;
            };
          } | null;
          readonly id: string;
          readonly productApp: {
            readonly customAppTitle: string | null;
          } | null;
        };
      }>;
    };
  } | null;
  readonly productApp: {
    readonly customAppTitle: string | null;
  } | null;
  readonly showComments: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentUsageFragment" | "ContentFooterSection_ContentUsageFragment" | "ContentUsageUtils_useContentUsagePathContentUsageFragment" | "DeleteContentUsageButtonFragment">;
  readonly " $fragmentType": "ContentUsageGridItemFragment";
};
export type ContentUsageGridItemFragment$key = {
  readonly " $data"?: ContentUsageGridItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageGridItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "customAppTitle",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductApp",
  "kind": "LinkedField",
  "name": "productApp",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageGridItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerBookmarked",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonContentUsageFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Collection",
                      "kind": "LinkedField",
                      "name": "collection",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductApp",
                          "kind": "LinkedField",
                          "name": "app",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Curriculum",
                      "kind": "LinkedField",
                      "name": "curriculum",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "product",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverPhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverVideo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showCoverPlayIcon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetUsage",
          "kind": "LinkedField",
          "name": "coverVideoAssetUsage",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Asset",
              "kind": "LinkedField",
              "name": "asset",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DiscoVideoFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentFooterSection_ContentFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BookmarkButtonContentFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteContentUsageButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentFooterSection_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useContentUsagePathContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "e0a487b824c13c1df5d0ad1ef5ae0799";

export default node;
