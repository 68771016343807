/**
 * @generated SignedSource<<6f10108388ac9bdc7a98da46e872315b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageLockIconFragment$data = {
  readonly content: {
    readonly name: string | null;
  };
  readonly entity: ContentUsageEntity;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateContentModuleButtonFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "ContentUsageLockIconFragment";
};
export type ContentUsageLockIconFragment$key = {
  readonly " $data"?: ContentUsageLockIconFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageLockIconFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageLockIconFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateContentModuleButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "7220dfe2a79cfe1627d531c3ead9a4b9";

export default node;
