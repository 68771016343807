/**
 * @generated SignedSource<<8d9192a2dba6127884116f50c66b2f49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurriculumConfirmationModalConfirmationModalFragment$data = {
  readonly ctaText: string | null;
  readonly name: string | null;
  readonly richEditorDescriptionContent: string | null;
  readonly " $fragmentType": "CurriculumConfirmationModalConfirmationModalFragment";
};
export type CurriculumConfirmationModalConfirmationModalFragment$key = {
  readonly " $data"?: CurriculumConfirmationModalConfirmationModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumConfirmationModalConfirmationModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurriculumConfirmationModalConfirmationModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorDescriptionContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaText",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "ac33b45e84291c82aaf46385324e5d08";

export default node;
