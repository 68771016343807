/**
 * @generated SignedSource<<12535f762fe0699b03a4f281f4b91b8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type WebFormSubmissionsOrderByField = "completed_at" | "created_at" | "duration" | "score" | "user_name" | "%future added value";
export type WebFormSubmissionsOrderByInput = {
  direction: OrderDirection;
  field: WebFormSubmissionsOrderByField;
};
export type QuizSubmissionResultSidebarQuery$variables = {
  contentUsageId: string;
  orderBy?: WebFormSubmissionsOrderByInput | null;
  revisionId: string;
  userIds: ReadonlyArray<string>;
};
export type QuizSubmissionResultSidebarQuery$data = {
  readonly revision: {
    readonly id?: string;
    readonly submissions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly completedAt: string | null;
          readonly hasPassed: boolean | null;
          readonly id: string;
          readonly organizationMembershipId: string | null;
          readonly result: {
            readonly percentage: number;
          } | null;
        };
      }>;
    };
  } | null;
};
export type QuizSubmissionResultSidebarQuery = {
  response: QuizSubmissionResultSidebarQuery$data;
  variables: QuizSubmissionResultSidebarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "revisionId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIds"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "revisionId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "contentUsageId",
      "variableName": "contentUsageId"
    },
    {
      "kind": "Variable",
      "name": "orderBy",
      "variableName": "orderBy"
    },
    {
      "kind": "Variable",
      "name": "userIds",
      "variableName": "userIds"
    }
  ],
  "concreteType": "WebFormSubmissionNodeConnection",
  "kind": "LinkedField",
  "name": "submissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormSubmissionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormSubmission",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPassed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "organizationMembershipId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormSubmissionResult",
              "kind": "LinkedField",
              "name": "result",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percentage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSubmissionResultSidebarQuery",
    "selections": [
      {
        "alias": "revision",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "WebFormRevision",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuizSubmissionResultSidebarQuery",
    "selections": [
      {
        "alias": "revision",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "type": "WebFormRevision",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12cc8ec2b12366fe2307e3fced3903ab",
    "id": null,
    "metadata": {},
    "name": "QuizSubmissionResultSidebarQuery",
    "operationKind": "query",
    "text": "query QuizSubmissionResultSidebarQuery(\n  $contentUsageId: ID!\n  $revisionId: ID!\n  $userIds: [ID!]!\n  $orderBy: WebFormSubmissionsOrderByInput\n) {\n  revision: node(id: $revisionId) {\n    __typename\n    ... on WebFormRevision {\n      id\n      submissions(userIds: $userIds, contentUsageId: $contentUsageId, orderBy: $orderBy) {\n        edges {\n          node {\n            id\n            completedAt\n            hasPassed\n            organizationMembershipId\n            result {\n              percentage\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f24f3ae76d6cd8d0034315695adaa6d9";

export default node;
