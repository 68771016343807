import { UpdateContentUsageInput } from "@/content-usage/modules/actions/__generated__/ContentModuleForm_UpdateContentUsageMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoText,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

interface ContentModuleAdditionalDetailsProps extends TestIDProps {
  form: FormStore<UpdateContentUsageInput>
}

function ContentModuleAdditionalDetails({ form }: ContentModuleAdditionalDetailsProps) {
  const classes = useStyles()
  const [showAdvanced, setShowAdvanced] = useState(false)
  const copyToClipboard = useCopyToClipboard()
  const theme = useTheme()

  if (!form.state.contentUsageId) return null
  const curriculumModuleId = Relay.fromGlobalId(form.state.contentUsageId).id

  return (
    <>
      <DiscoDivider />
      <DiscoContainerButton
        className={classes.headerContainer}
        testid={"ExperienceSettingsAdditionalDetails.additional-information"}
        onClick={() => setShowAdvanced(!showAdvanced)}
      >
        <DiscoIcon
          icon={"iconsax.bold/arrow-down"}
          height={24}
          width={24}
          rotate={showAdvanced ? undefined : "-90"}
          color={theme.palette.text.primary}
        />
        <div className={classes.headerText}>
          <span className={classes.titleContainer}>
            <DiscoText variant={"body-md-600"}>{"Additional Information"}</DiscoText>
          </span>
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {`Additional information such as the Module's ID (can be used with the Disco-Zapier integration).`}
          </DiscoText>
        </div>
      </DiscoContainerButton>
      {showAdvanced && (
        <DiscoFormControl
          label={"Module ID"}
          testid={`ExperienceSettingsAdditionalDetails.ModuleId`}
          className={classes.moduleIdContainer}
        >
          <DiscoInput
            disabled={true}
            value={curriculumModuleId}
            fullWidth
            classes={{ input: classes.input, root: classes.inputRoot }}
            endAdornment={
              <DiscoIconButton
                style={{ padding: 0 }}
                onClick={() => copyToClipboard(curriculumModuleId)}
              >
                <DiscoIcon icon={"copy"} />
              </DiscoIconButton>
            }
          />
        </DiscoFormControl>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  moduleIdContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    alignItems: "flex-start",
    margin: theme.spacing(2, 0),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  headerText: {
    width: "100%",
  },
  inputRoot: {
    flexWrap: "wrap",
    height: "100%",
  },
  input: {
    flex: "1 0 fit-content",
  },
}))

export default ContentModuleAdditionalDetails
