/**
 * @generated SignedSource<<beb8bd4a0de6a74cec3c288e131a31de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentModuleCardFragment$data = {
  readonly content: {
    readonly children: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly content: {
            readonly type: ContentType;
          };
          readonly id: string;
          readonly viewerHasCompleted: boolean;
        };
      }>;
    } | null;
    readonly name: string | null;
  };
  readonly curriculum: {
    readonly product: {
      readonly slug: string;
    };
  } | null;
  readonly id: string;
  readonly isLocked: boolean;
  readonly releasedAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleAdminDropdownFragment" | "ContentUsageLockIconFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "ContentModuleCardFragment";
};
export type ContentModuleCardFragment$key = {
  readonly " $data"?: ContentModuleCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleCardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleCardFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "children",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "viewerHasCompleted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Content",
                      "kind": "LinkedField",
                      "name": "content",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Curriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageLockIconFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentModuleAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "e5ea417af13b05881ca5d811744256f6";

export default node;
