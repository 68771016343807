import BookmarkButton from "@/bookmarks/BookmarkButton"
import DeleteContentUsageButton from "@/content-usage/buttons/DeleteContentUsageButton"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { ContentUsageGridItemFragment$key } from "@/content-usage/modules/grid/__generated__/ContentUsageGridItemFragment.graphql"
import ContentFooterSection from "@/content/detail/sections/ContentFooterSection"
import Relay from "@/relay/relayUtils"
import defaultUserCoverPhoto from "@assets/images/covers/default-product-card-cover.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import DiscoVideo from "@disco-ui/video/DiscoVideo"
import { useTheme } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"
import { useHistory } from "react-router-dom"

interface ContentUsageGridItemProps extends TestIDProps {
  contentUsageKey: ContentUsageGridItemFragment$key
  showOverflow?: boolean | null
  className?: string
  navigateOnClick?: boolean
  onClick?: () => void
  hideMedia?: boolean
  showLocation?: boolean
  hideFooter?: boolean
  isDashboardBlock?: boolean
}

function ContentUsageGridItem({
  contentUsageKey,
  testid = "ContentUsageGridItem",
  showOverflow = true,
  className,
  navigateOnClick = false,
  onClick,
  hideMedia = false,
  showLocation = false,
  hideFooter = false,
  isDashboardBlock = false,
}: ContentUsageGridItemProps) {
  const contentUsage = useFragment<ContentUsageGridItemFragment$key>(
    graphql`
      fragment ContentUsageGridItemFragment on ContentUsage {
        id
        showComments
        hasViewerBookmarked
        productApp {
          customAppTitle
        }
        ...BookmarkButtonContentUsageFragment
        module {
          usages {
            edges {
              node {
                id
                productApp {
                  customAppTitle
                }

                collection {
                  id
                  app {
                    customAppTitle
                  }
                }
                curriculum {
                  id
                  product {
                    name
                  }
                }
              }
            }
          }
        }
        content {
          id
          type
          label
          name
          description
          coverPhoto
          coverVideo
          thumbnailUrl
          showCoverPlayIcon
          coverVideoAssetUsage {
            id
            asset {
              id
              ...DiscoVideoFragment
            }
          }
          productId
          ...ContentFooterSection_ContentFragment
          ...BookmarkButtonContentFragment
        }
        ...DeleteContentUsageButtonFragment
        ...ContentFooterSection_ContentUsageFragment
        ...ContentUsageUtils_useContentUsagePathContentUsageFragment
      }
    `,
    contentUsageKey
  )

  const history = useHistory()
  const path = ContentUsageUtils.useContentUsagePath(contentUsage)
  const drawer = useContentUsageDrawer()
  const permissions = useActiveProductOrOrganizationPermissions()
  const classes = useStyles({ isDashboardBlock })
  const { content } = contentUsage
  const TESTID = `${testid}.${contentUsage.content.name}`

  const modules = Relay.connectionToArray(contentUsage.module?.usages)
  const moduleUsage = modules.length ? modules[0] : null
  const onShowHoverClasses = useShowOnHoverStyles()

  return (
    <DiscoContainerButton
      className={classNames(classes.container, onShowHoverClasses.hoverable, className)}
      onClick={openDrawer}
      testid={TESTID}
    >
      {hideMedia ? null : content.coverVideo || content.coverVideoAssetUsage ? (
        <DiscoVideo
          radius={"big"}
          src={content.coverVideo}
          // thumbnailUrl was created to replace coverPhoto, but both are still used so fallback to coverPhoto
          poster={content.thumbnailUrl || content.coverPhoto}
          assetKey={content.coverVideoAssetUsage?.asset}
          className={classes.videoWrapper}
          disablePlay
          playButton={{ hidden: !content.showCoverPlayIcon, size: "small" }}
          isGridItem
        />
      ) : (
        <CoverPhoto
          coverPhoto={content.thumbnailUrl || content.coverPhoto || defaultUserCoverPhoto}
          customClassName={classes.coverPhoto}
          isGridItem
        />
      )}

      <div className={classes.item}>
        <DiscoText variant={"body-sm-600-uppercase"} color={"primary.main"}>
          {content.label}
        </DiscoText>
        <DiscoText testid={`${testid}.name`} variant={"body-md-600"} truncateText={1}>
          {content.name}
        </DiscoText>

        {showLocation && renderUsageLocation()}

        {!hideFooter && (
          <ContentFooterSection
            contentUsageKey={contentUsage}
            contentKey={content!}
            readOnlyReactionButton
            testid={testid}
            onClick={goToCommentsPage}
            bookmarkKind={"content_usage"}
            hideCommentButton={!contentUsage.showComments}
            hideReactionsList
            hideText
            customHoverClasses={onShowHoverClasses.showable}
          />
        )}

        {showOverflow && permissions.has("content.manage") && (
          <DiscoMoreActionsDropdown
            testid={testid}
            rotateIcon
            moreActionsButtonClassName={classNames(
              classes.moreActionsButton,
              onShowHoverClasses.showable
            )}
          >
            <DeleteContentUsageButton contentUsageKey={contentUsage}>
              {(buttonProps) => (
                <DiscoDropdownItem
                  {...buttonProps}
                  title={"Delete"}
                  testid={`${TESTID}.delete-button`}
                />
              )}
            </DeleteContentUsageButton>
          </DiscoMoreActionsDropdown>
        )}
      </div>
    </DiscoContainerButton>
  )

  function renderUsageLocation() {
    let usageLocation = "Community"
    if (moduleUsage) {
      usageLocation = moduleUsage.collection?.id
        ? moduleUsage.collection.app.customAppTitle || ""
        : moduleUsage.curriculum?.id
        ? moduleUsage.curriculum?.product.name || ""
        : moduleUsage.productApp?.customAppTitle || ""
    }

    return usageLocation?.length ? (
      <div className={classes.location}>
        <DiscoText
          variant={"body-sm"}
          color={"text.secondary"}
          align={"left"}
          truncateText={1}
        >
          {`in ${usageLocation}`}
        </DiscoText>

        <BookmarkButton
          testid={`${testid}.ContentFooterSection`}
          contentKey={content}
          contentUsageKey={contentUsage || undefined}
          kind={"content_usage"}
          className={classNames(classes.bookmark, {
            [onShowHoverClasses.showable]: !contentUsage.hasViewerBookmarked,
          })}
          showText={false}
        />
      </div>
    ) : null
  }

  function openDrawer() {
    if (onClick) onClick()
    if (navigateOnClick) {
      history.push({
        ...location,
        pathname: path,
        search: setSearchParams(location.search, {
          contentId: undefined,
          contentTemplate: undefined,
          u: Relay.fromGlobalId(contentUsage.id).id,
        }),
      })
      return
    }
    drawer.open({
      drawerContentUsageId: contentUsage.id,
    })
  }

  function goToCommentsPage(e?: React.MouseEvent<HTMLButtonElement>) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    drawer.open({
      drawerContentUsageId: contentUsage!.id,
      drawerTab: "comments",
    })
  }
}

interface StyleProps {
  isDashboardBlock: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    borderRadius: theme.measure.borderRadius.big,
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.palette.groovyDepths.xs,
    border: theme.palette.constants.borderDashboardCard,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  item: ({ isDashboardBlock }: StyleProps) => ({
    position: "relative",
    display: "grid",
    textAlign: "left",
    justifyContent: "normal",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    transition: "box-shadow 0.2s ease-in-out",

    ...styleIf(!isDashboardBlock, {
      width: "100%",
    }),
  }),
  coverPhoto: {
    width: "100%",
    objectFit: "cover",
    borderRadius: 0,
    borderTopRightRadius: theme.measure.borderRadius.big,
    borderTopLeftRadius: theme.measure.borderRadius.big,
  },
  moreActionsButton: {
    background: theme.palette.groovy.neutral[100],
    position: "absolute",
    zIndex: 2,
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
  videoWrapper: {
    // To make the video container the same size as the cover photo
    aspectRatio: "2",
    borderRadius: 0,
    borderTopRightRadius: theme.measure.borderRadius.big,
    borderTopLeftRadius: theme.measure.borderRadius.big,
  },
  location: {
    position: "relative",
    overflow: "hidden",
  },
  bookmark: {
    position: "absolute",
    right: 0,
    bottom: theme.spacing(-0.5),
  },
}))

interface SkeletonProps {
  width?: string
  height?: string
}
export function ContentUsageGridItemSkeleton({
  width = "100%",
  height = "228px",
}: SkeletonProps) {
  const theme = useTheme()

  return (
    <Skeleton
      variant={"rect"}
      style={{
        borderRadius: theme.measure.borderRadius.big,
        width,
        height,
      }}
    />
  )
}

export default ContentUsageGridItem
