/**
 * @generated SignedSource<<195cb4785bd9df1c138234d5646c1987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModuleReleaseCountdownFragment$data = {
  readonly releasedAt: string | null;
  readonly " $fragmentType": "ContentModuleReleaseCountdownFragment";
};
export type ContentModuleReleaseCountdownFragment$key = {
  readonly " $data"?: ContentModuleReleaseCountdownFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleReleaseCountdownFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleReleaseCountdownFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "cd0b82215724cca5253877f7eb241ff9";

export default node;
