/**
 * @generated SignedSource<<0e60a7fdfaf2217e48c9d39e4012e98a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateContentModuleItemButtonFragment$data = {
  readonly entity: ContentUsageEntity;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreateCollectionModuleItemContentUsageButtonFragment" | "CreateCurriculumModuleItemContentUsageButtonFragment">;
  readonly " $fragmentType": "CreateContentModuleItemButtonFragment";
};
export type CreateContentModuleItemButtonFragment$key = {
  readonly " $data"?: CreateContentModuleItemButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateContentModuleItemButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateContentModuleItemButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCollectionModuleItemContentUsageButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCurriculumModuleItemContentUsageButtonFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "b99325b5d8a0cb0aac9e09e3d5d246df";

export default node;
