import { ContentModuleCompletedUsersAvatarStackQuery } from "@/content-usage/modules/components/__generated__/ContentModuleCompletedUsersAvatarStackQuery.graphql"
import { useLabels } from "@/core/context/LabelsContext"
import DiscoBallIcon from "@/core/ui/images/empty-state-illustrations/disco-ball.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CurriculumProfileListModal from "@/product/course/curriculum/common/modal/CurriculumProfileListModal"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ProfileAvatarStack, {
  ProfileAvatarStackSkeleton,
} from "@/user/common/avatar-stack/ProfileAvatarStack"
import ProfileListDrawer from "@/user/common/profile-list-drawer/ProfileListDrawer"
import { DiscoChipSkeleton, DiscoEmptyState, DiscoText } from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props {
  contentUsageId: GlobalID
  stackSize?: number
}

const ContentModuleCompletedUsersAvatarStack: React.FC<Props> = (props) => {
  const { contentUsageId, stackSize = 4 } = props

  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))
  const classes = useStyles()
  const { product_member } = useLabels()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const { contentUsage } = useLazyLoadQuery<ContentModuleCompletedUsersAvatarStackQuery>(
    graphql`
      query ContentModuleCompletedUsersAvatarStackQuery($id: ID!) {
        contentUsage: node(id: $id) {
          ... on ContentUsage {
            content {
              name
            }
            completedProductMemberships {
              totalCount
              edges {
                node {
                  id
                  member {
                    ...ProfileListDrawerListItemFragment
                    ...ProfileAvatarStackFragment @relay(mask: false)
                  }
                }
              }
            }
            ...usePermissionsFragment
          }
        }
      }
    `,
    { id: contentUsageId },
    { fetchPolicy: "store-and-network" }
  )
  const permissions = usePermissions(contentUsage)

  if (!contentUsage?.completedProductMemberships) return null

  const completedProductMembers = Relay.connectionToArray(
    contentUsage.completedProductMemberships
  )
  const totalUsers = contentUsage.completedProductMemberships.totalCount

  if (!isMdUp) return null
  return (
    <div className={classes.completionContainer}>
      <>
        <ProfileAvatarStack
          testid={"ContentModuleCompletedUsersAvatarStack"}
          users={completedProductMembers.map((cpm) => cpm.member)}
          totalUsers={totalUsers}
          stackSize={stackSize}
          onClick={onOpen}
          countVariant={"always"}
          textVariant={"body-xs-500"}
          textColor={"text.secondary"}
          totalUsersCopy={`${totalUsers} completed`}
        />

        {permissions.has("curriculum.manage") ? (
          <CurriculumProfileListModal
            isOpen={isOpen}
            onClose={onClose}
            contentUsageId={contentUsageId}
          />
        ) : (
          <ProfileListDrawer
            isOpen={isOpen}
            onClose={onClose}
            users={completedProductMembers.map((cpm) => ({
              ...cpm.member,
              firstName: cpm.member.firstName || "",
            }))}
            title={`Completed ${product_member.plural}`}
            subtitle={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {`${product_member.plural} who complete the module `}

                <DiscoText
                  variant={"body-sm-500"}
                  component={"span"}
                  color={"text.primary"}
                >
                  {contentUsage?.content?.name}
                </DiscoText>
              </DiscoText>
            }
            emptyState={
              <DiscoEmptyState
                testid={"ContentModuleCompletedUsersAvatarStack.EmptyState"}
                icon={<DiscoBallIcon height={"100px"} />}
                title={"Be the first to complete this module!"}
                subtitle={`No one has completed this module yet. ${product_member.plural} name will appear here upon completion.`}
                className={classes.emptyState}
              />
            }
          />
        )}
      </>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  completionContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    gap: theme.spacing(1),
  },
  emptyState: {
    padding: theme.spacing(4, 0),
  },
}))

export const ContentModuleCompletedUsersAvatarStackSkeleton: React.FC = () => {
  return (
    <div>
      <ProfileAvatarStackSkeleton />
      <DiscoChipSkeleton width={"90px"} />
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: ContentModuleCompletedUsersAvatarStack,
  skeleton: ContentModuleCompletedUsersAvatarStackSkeleton,
})
