import pluralize from "pluralize"

export function formatTimeMinutes(minutes: number): string {
  if (minutes < 60) {
    return `${minutes} ${pluralize("min", minutes)}`
  }

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (remainingMinutes === 0) {
    return `${hours} ${pluralize("hr", hours)}`
  }

  return `${hours} ${pluralize("hr", hours)} ${remainingMinutes} ${pluralize(
    "min",
    remainingMinutes
  )}`
}
