/**
 * @generated SignedSource<<2190e0cee0698a84e66b81e269c52cb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModuleEventsListItem_OccurrenceFragment$data = {
  readonly attendees: {
    readonly totalCount: number;
  } | null;
  readonly coverPhoto: string | null;
  readonly datetimeRange: ReadonlyArray<string>;
  readonly event: {
    readonly id: string;
    readonly memberGroups: {
      readonly totalCount: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"MemberGroupEventTagListFragment" | "usePermissionsFragment">;
  };
  readonly id: string;
  readonly isComplete: boolean;
  readonly name: string | null;
  readonly product: {
    readonly cover: string;
    readonly name: string;
  };
  readonly rsvps: {
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "ContentModuleEventsListItem_OccurrenceFragment";
};
export type ContentModuleEventsListItem_OccurrenceFragment$key = {
  readonly " $data"?: ContentModuleEventsListItem_OccurrenceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleEventsListItem_OccurrenceFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleEventsListItem_OccurrenceFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverPhoto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cover",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberGroupEventTagListFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePermissionsFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupNodeConnection",
          "kind": "LinkedField",
          "name": "memberGroups",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceRSVPNodeConnection",
      "kind": "LinkedField",
      "name": "rsvps",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNodeConnection",
      "kind": "LinkedField",
      "name": "attendees",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "65948e5f238337d9c3f8b029e12e324a";

export default node;
