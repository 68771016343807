/**
 * @generated SignedSource<<18b4f2aea66afa9c1d9ed6125fc621db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PreviewCurriculumItemDueDateChangeEmailInput = {
  body: string;
  name: string;
  newDueDate?: string | null;
  oldDueDate?: string | null;
  productId: string;
  subject: string;
};
export type UpdateContentDueDateNotificationModal_PreviewMutation$variables = {
  input: PreviewCurriculumItemDueDateChangeEmailInput;
};
export type UpdateContentDueDateNotificationModal_PreviewMutation$data = {
  readonly response: {
    readonly data: boolean | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type UpdateContentDueDateNotificationModal_PreviewMutation = {
  response: UpdateContentDueDateNotificationModal_PreviewMutation$data;
  variables: UpdateContentDueDateNotificationModal_PreviewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PreviewCurriculumItemDueDateChangeEmailResponse",
    "kind": "LinkedField",
    "name": "previewCurriculumItemDueDateChangeEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateContentDueDateNotificationModal_PreviewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateContentDueDateNotificationModal_PreviewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f459cceba6ac67ba500cc3d12fb2852",
    "id": null,
    "metadata": {},
    "name": "UpdateContentDueDateNotificationModal_PreviewMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateContentDueDateNotificationModal_PreviewMutation(\n  $input: PreviewCurriculumItemDueDateChangeEmailInput!\n) {\n  response: previewCurriculumItemDueDateChangeEmail(input: $input) {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33005b7fd05c8034b2cb7dac0fd6a679";

export default node;
