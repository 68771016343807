import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import ContentModuleAdditionalDetails from "@/content-usage/modules/actions/ContentModuleAdditionalDetails"
import ContentModuleAssociateEventsFormField from "@/content-usage/modules/actions/ContentModuleAssociateEventsFormField"
import { ContentModuleFormState } from "@/content-usage/modules/actions/ContentModuleForm"
import ContentModulePrerequisitesForm from "@/content-usage/modules/actions/ContentModulePrerequisitesForm"
import ContentModuleReleaseDateFormField from "@/content-usage/modules/actions/ContentModuleReleaseDateFormField"
import {
  ContentModuleForm_UpdateContentUsageMutation,
  ContentUsageEntity,
} from "@/content-usage/modules/actions/__generated__/ContentModuleForm_UpdateContentUsageMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface ContentModuleFormFieldsProps extends TestIDProps {
  form: FormStore<ContentModuleFormState, ContentModuleForm_UpdateContentUsageMutation>
  isCreation?: boolean
  parentEntity: ContentUsageEntity
}

function ContentModuleFormFields({
  form,
  testid,
  isCreation,
  parentEntity,
}: ContentModuleFormFieldsProps) {
  const { connectedApps } = useConnectedProductApps()

  return (
    <>
      {renderTitleInput()}
      {renderDescriptionInput()}
      {renderReleasedDateField()}
      {renderPrerequisitesForm()}
      {renderAssociateEventsField()}
      {renderAdditionalDetails()}
    </>
  )

  function renderTitleInput() {
    return (
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Title"}
          </DiscoText>
        }
        error={Boolean(form.errorsByField.name)}
        errorMessages={form.errorsByField.name}
      >
        <DiscoInput
          value={form.state.content?.name || ""}
          onChange={(e) => {
            if (form.state.content) form.state.content.name = e.target.value
            else form.state.content = { name: e.target.value }
          }}
          fullWidth
          data-testid={`${testid}.name`}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )
  }

  function renderDescriptionInput() {
    if (parentEntity !== "curriculum") return null

    return (
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Description"}
          </DiscoText>
        }
        errorMessages={form.errorsByField.description}
      >
        <DiscoInput
          inputProps={{
            "data-testid": `${testid}.description`,
          }}
          value={form.state.content?.description || ""}
          onChange={(e) => {
            if (form.state.content) form.state.content.description = e.target.value
            else form.state.content = { description: e.target.value }
          }}
          multiline
        />
      </DiscoFormControl>
    )
  }

  function renderReleasedDateField() {
    if (parentEntity !== "curriculum") return null

    return (
      <ContentModuleReleaseDateFormField
        form={form}
        isCreation={isCreation}
        parentEntity={parentEntity}
      />
    )
  }

  function renderPrerequisitesForm() {
    if (parentEntity !== "curriculum") return null

    return <ContentModulePrerequisitesForm form={form} parentEntity={parentEntity} />
  }

  function renderAdditionalDetails() {
    if (parentEntity !== "curriculum") return null

    return <ContentModuleAdditionalDetails form={form} />
  }

  function renderAssociateEventsField() {
    if (parentEntity !== "curriculum") return null
    if (!connectedApps.has("events")) return null
    if (!isCreation) return null

    return <ContentModuleAssociateEventsFormField form={form} />
  }
}

export default observer(ContentModuleFormFields)
