/**
 * @generated SignedSource<<60e4b78073599e5dde07782e6143132a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModulePrerequisitesFormQuery$variables = {
  id: string;
};
export type ContentModulePrerequisitesFormQuery$data = {
  readonly product: {
    readonly curriculum?: {
      readonly modules: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly content: {
              readonly name: string | null;
            };
            readonly createdAt: string;
            readonly id: string;
            readonly ordering: number | null;
            readonly releasedAt: string | null;
            readonly " $fragmentSpreads": FragmentRefs<"ContentUsagePrerequisiteItemFragment">;
          };
        }>;
      };
    } | null;
    readonly id: string;
  } | null;
};
export type ContentModulePrerequisitesFormQuery = {
  response: ContentModulePrerequisitesFormQuery$data;
  variables: ContentModulePrerequisitesFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordering",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releasedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCurriculumModule",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasCompleted",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentModulePrerequisitesFormQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Curriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsage",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Content",
                                "kind": "LinkedField",
                                "name": "content",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ContentUsagePrerequisiteItemFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentModulePrerequisitesFormQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Curriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsage",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Content",
                                "kind": "LinkedField",
                                "name": "content",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v2/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "label",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "creationDatetime",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "coverVideo",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "thumbnailUrl",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "showCoverPlayIcon",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "description",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ContentUsageNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "children",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "totalCount",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ContentUsageNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ContentUsage",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              (v9/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Content",
                                                "kind": "LinkedField",
                                                "name": "content",
                                                "plural": false,
                                                "selections": [
                                                  (v8/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "contentLabelId",
                                                    "storageKey": null
                                                  },
                                                  (v2/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dueAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "entity",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Content",
                                "kind": "LinkedField",
                                "name": "module",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isCollectionModule",
                                    "storageKey": null
                                  },
                                  (v6/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isLocked",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3caa3abe0e2000905caf0e3c1793f08d",
    "id": null,
    "metadata": {},
    "name": "ContentModulePrerequisitesFormQuery",
    "operationKind": "query",
    "text": "query ContentModulePrerequisitesFormQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    id\n    ... on Product {\n      id\n      curriculum {\n        modules {\n          edges {\n            node {\n              id\n              ordering\n              releasedAt\n              createdAt\n              content {\n                name\n                id\n              }\n              ...ContentUsagePrerequisiteItemFragment\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment ContentThumbnailWithDetails_ContentFragment on Content {\n  id\n  type\n  label\n  name\n  creationDatetime\n  ...ContentThumbnail_ContentFragment\n}\n\nfragment ContentThumbnailWithDetails_ContentUsageFragment on ContentUsage {\n  id\n  dueAt\n  entity\n  product {\n    name\n    id\n  }\n  module {\n    isCurriculumModule\n    isCollectionModule\n    name\n    id\n  }\n}\n\nfragment ContentThumbnail_ContentFragment on Content {\n  id\n  type\n  coverVideo\n  thumbnailUrl\n  showCoverPlayIcon\n}\n\nfragment ContentUsagePrerequisiteItemFragment on ContentUsage {\n  id\n  releasedAt\n  viewerHasCompleted\n  ...ContentThumbnailWithDetails_ContentUsageFragment\n  isLocked\n  content {\n    id\n    isCurriculumModule\n    ...ContentThumbnailWithDetails_ContentFragment\n    name\n    description\n    children {\n      totalCount\n      edges {\n        node {\n          id\n          viewerHasCompleted\n          content {\n            type\n            contentLabelId\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "518ba1446344ab436687fcd25a717c26";

export default node;
